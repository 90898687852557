import Image from "next/image";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import promo from "@/features/jobSearch/assets/promoImg.png";
import { JOB_COUNT } from "@/constant";

function Register({ dispatch, updateAuthTabStatus, setSignUpOpen }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        p: { xs: "16px", md: "20px" },
        borderRadius: "12px",
        my: "16px",
        bgcolor: (theme) => theme.palette.primary[1000],
      }}
      columnGap={1}
    >
      <Image
        height={120}
        width={60}
        style={{ width: "auto", height: "auto" }}
        src={promo}
        alt="Job Seekers"
        title="Job Seekers"
      />
      <Stack sx={{ flexGrow: 1 }}>
        <Typography
          component="h3"
          sx={{ fontSize: { xs: "16px", md: "20px" }, fontWeight: 600 }}
        >
          What Job Seekers will get?
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 0, md: 2 }}
          rowGap={1}
          mt={1}
          sx={{
            "& .MuiTypography-root": {
              fontSize: { xs: "13px", md: "16px" },
            },
            "& i": {
              color: (theme) => theme.palette.warning.main,
              fontSize: { xs: "16px", md: "20px" },
            },
          }}
        >
          <Stack direction="row" alignItems="center" columnGap={1}>
            <i className="icon-check"></i>
            <Typography>{JOB_COUNT}+ Teaching & Non-Teaching Jobs</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" columnGap={1}>
            <i className="icon-check"></i>
            <Typography>Personalised Job Recommendation</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" columnGap={1}>
            <i className="icon-check"></i>
            <Typography>Free Resume Builder</Typography>
          </Stack>
        </Stack>
        <Box
          className="contained-button"
          onClick={() => {
            dispatch(setSignUpOpen(true));
            localStorage.removeItem("jobid");
            dispatch(updateAuthTabStatus("Seeker"));
          }}
          sx={{
            width: "fit-content",
            fontSize: { xs: "14px", lg: "16px" },
            mx: "auto",
            mt: 2,
          }}
        >
          <span className="icon-user-add" style={{ fontSize: "20px", marginRight:"6px" }}></span>{" "}
          Registration for Free
        </Box>
      </Stack>
    </Stack>
  );
}
export default Register;
